<template>
  <div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 28px;' @click='goToSponsor(297)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 228px;' @click='goToSponsor(298)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 428px;' @click='goToSponsor(299)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 632px;' @click='goToSponsor(302)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 832px;' @click='goToSponsor(301)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 1032px;' @click='goToSponsor(300)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 184px; height: 90px; bottom: 248px; left: 1232px;' @click='goToSponsor(303)'>
    </div>

    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; left: 20px;' @click='goToSponsor(296)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; left: 310px;' @click='goToSponsor(294)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; left: 590px;' @click='goToSponsor(292)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; left: 870px;' @click='goToSponsor(293)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; left: 1150px;' @click='goToSponsor(295)'>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExhibitHallSponsorClickArea3',
  methods: {
    goToSponsor (sponsorId) {
      this.$router.push({
        name: 'Sponsor',
        query: {
          sponsor_id: sponsorId,
        },
      })
    },
  },
}
</script>
