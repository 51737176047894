<template>
  <div class='fade-banner-images'>
    <img v-for='(imageName, index) in images' :key='`fade-banner-image-${imageName}`' :src='fullImagePath(imageName)'
      class='object-cover h-full' :class='showThisImage(index)' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FadeBannerImages',
  props: [
    'images',
  ],
  data () {
    return {
      showingImageIndex: 0,
      replaceImageTimer: null,
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventImageS3Path',
    ]),
  },
  methods: {
    fullImagePath (imageName) {
      return `${this.eventImageS3Path}/${imageName}`
    },
    showThisImage (index) {
      return (this.showingImageIndex == index) ? 'show-this-image' : ''
    },
  },
  beforeDestroy() {
    clearInterval(this.currentTimeUpdater)
  },
  mounted() {
    this.currentTimeUpdater = setInterval(() => {
      let index = this.showingImageIndex + 1
      if (index == this.images.length) {
        this.showingImageIndex = 0
      } else {
        this.showingImageIndex = index
      }
    }, 5000)
  },
}
</script>

<style lang='scss' scoped>
.fade-banner-images img {
  position: absolute;
  left: 0;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  -o-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.fade-banner-images img.show-this-image {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=1);
}

</style>



