<template>
  <div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 200px; height: 120px; bottom: 248px; left: 84px;' @click='goToSponsor(291)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 200px; height: 120px; bottom: 248px; left: 300px;' @click='goToSponsor(290)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 200px; height: 120px; bottom: 248px; left: 518px;' @click='goToSponsor(286)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 200px; height: 120px; bottom: 248px; left: 736px;' @click='goToSponsor(287)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 200px; height: 120px; bottom: 248px; left: 962px;' @click='goToSponsor(288)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 200px; height: 120px; bottom: 248px; left: 1182px;' @click='goToSponsor(289)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; left: 412px;' @click='goToSponsor(284)'>
    </div>
    <div class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25'
      style='width: 264px; height: 132px; bottom: 68px; right: 412px;' @click='goToSponsor(285)'>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExhibitHallSponsorClickArea2',
  methods: {
    goToSponsor (sponsorId) {
      this.$router.push({
        name: 'Sponsor',
        query: {
          sponsor_id: sponsorId,
        },
      })
    },
  },
}
</script>
