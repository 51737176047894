<template>
  <div class='overflow-x-auto lg:overflow-visible'>
    <div class='handle-page-size-difference exhibition-hall'>
      <div class='relative'>
        <div class='absolute top-8 left-8 flex flex-col justify-start items-start gap-x-1 text-sm'>
          <button @click='setHallNumber(1)'
            class='hall-button w-64 text-left whitespace-nowrap px-4 py-3 uppercase rounded hover:bg-gray-100'
            :class='isSelectedHall(1)' :style='isSelectedBackgroundColor(1)'>
            Diamond
          </button>
          <button @click='setHallNumber(2)'
            class='hall-button w-64 text-left whitespace-nowrap px-4 py-3 uppercase rounded hover:bg-gray-100'
            :class='isSelectedHall(2)' :style='isSelectedBackgroundColor(2)'>
            Platinum & Gold
          </button>
          <button @click='setHallNumber(3)'
            class='hall-button w-64 text-left whitespace-nowrap px-4 py-3 uppercase rounded hover:bg-gray-100'
            :class='isSelectedHall(3)' :style='isSelectedBackgroundColor(3)'>
            Silver, Bronze, Friends
          </button>
        </div>
        <button v-if='hallNumber !== 1'
          class='go-left-button absolute text-white rounded-full border-2 border-solid border-white shadow hover:shadow-xl'
          style='z-index: 200; top:calc(50% - 0.5rem);' @click='hallNumberIncrement(-1)'>
          <arrow-narrow-left-icon class='h-12 w-12' />
        </button>
        <button v-if='hallNumber !== 3'
          class='go-right-button absolute text-white rounded-full border-2 border-solid border-white shadow hover:shadow-xl'
          style='z-index: 200; top:calc(50% - 0.5rem);' @click='hallNumberIncrement(1)'>
          <arrow-narrow-right-icon class='h-12 w-12' />
        </button>
        <fade-banner-images v-if='false' :images='leftImages' class='absolute'
          style='top:149px; width:123px; height:233px; left:370px; ' />
        <fade-banner-images v-if='false' :images='rightImages' class='absolute'
          style='top:149px; width:123px; height:233px; right:366px;' />
        <component :is='exhibitHallClickArea' />
        <img class='w-full block' :src='exhibitHallBgImage' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions }                    from 'vuex'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from 'vue-tabler-icons'
import FadeBannerImages                              from '@/components/khrs-virtual/FadeBannerImages.vue'
import ExhibitHallSponsorClickArea1                  from '@/components/khrs-virtual/ExhibitHallSponsorClickArea1.vue'
import ExhibitHallSponsorClickArea2                  from '@/components/khrs-virtual/ExhibitHallSponsorClickArea2.vue'
import ExhibitHallSponsorClickArea3                  from '@/components/khrs-virtual/ExhibitHallSponsorClickArea3.vue'


export default {
  name: 'ExhibitionHall',
  components: {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    FadeBannerImages,
    ExhibitHallSponsorClickArea1,
    ExhibitHallSponsorClickArea2,
    ExhibitHallSponsorClickArea3,
  },
  data () {
    return {
      hallNumber: 1,
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'sponsorHallBannerImages',
      'sponsorHallBannerImages',
    ]),
    exhibitHallBgImage () {
      return `https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_sponsors_hall_${this.hallNumber}_v3.png`
    },
    leftImages () {
      return this.sponsorHallBannerImages('left')
    },
    rightImages () {
      return this.sponsorHallBannerImages('right')
    },
    exhibitHallClickArea () {
      return `exhibit-hall-sponsor-click-area${this.hallNumber}`
    },
  },
  methods: {
    ...mapActions('sponsors', [
      'getSponsors',
    ]),
    setHallNumber (hall) {
      this.hallNumber = hall
      this.$router.push({
        name: 'ExhibitionHall',
        query: {
          hallNumber: this.hallNumber
        },
      }).catch(() => {})
    },
    isSelectedHall (hall) {
      return (this.hallNumber === hall) ? 'font-semibold text-white' : ''
    },
    isSelectedBackgroundColor (hall) {
      return (this.hallNumber === hall) ? `background-color: ${this.eventMainThemeColor}` : 'background-color: #FFFFFF44'
    },
    hallNumberIncrement (increment) {
      this.hallNumber += increment
      this.$router.push({
        name: 'ExhibitionHall',
        query: {
          hallNumber: this.hallNumber
        },
      }).catch(() => {})
    },
    goToSponsor (sponsorId) {
      this.$router.push({
        name: 'Sponsor',
        params: {
          sponsor_id: sponsorId,
        },
      })
    },
  },
  mounted () {
    this.getSponsors('ExhibitionHall') // used so that we can forward to login page if not logged in
    let hall = parseInt(this.$route.query.hallNumber)
    if (hall && (hall > 1 && hall < 4)) { // ignore if event is 1
      this.setHallNumber(hall)
    }
  },
}
</script>

<style lang='scss'>
  .exhibition-hall {
    margin-left: 0;
    width: 1440px;
    max-width: 1440px;


    .go-left-button {
      left: 1rem;
    }

    .go-right-button {
      right: 1rem;
    }

    iframe {
      box-sizing: content-box;
    }


  }
  @media (min-width: 1280px) { 
    .handle-page-size-difference {
      margin-left: -80px; // this page is 1440, need to move it half the difference
    }

    .go-left-button {
      left: 1rem;
    }

    .go-right-button {
      right: -120px;
    }
  }

</style>



