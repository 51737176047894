<template>
  <div>
    <div 
      class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25' 
      style='width: 232px; height: 120px; bottom: 248px; left: 370px;'
      @click='goToSponsor(282)'>
    </div>
    <div 
      class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25' 
      style='width: 232px; height: 120px; bottom: 248px; left: 605px;'
      @click='goToSponsor(281)'>
    </div>
    <div 
      class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25' 
      style='width: 232px; height: 120px; bottom: 248px; left: 840px;'
      @click='goToSponsor(283)'>
    </div>
    <div 
      class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25' 
      style='width: 264px; height: 132px; bottom: 68px; left: 412px;'
      @click='goToSponsor(279)'>
    </div>
    <div 
      class='bg-gray-50 absolute cursor-pointer rounded-md opacity-0 hover:opacity-25' 
      style='width: 264px; height: 132px; bottom: 68px; right: 412px;'
      @click='goToSponsor(280)'>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExhibitHallSponsorClickArea1',
  methods: {
    goToSponsor (sponsorId) {
      this.$router.push({
        name: 'Sponsor',
        query: {
          sponsor_id: sponsorId,
        },
      })
    },
  },
}
</script>
